import { useRouter } from 'next/router'

import ButtonBlue from '../components/ui/button/_color/_blue'

import styles from './404.module.scss'
import { useTranslation } from 'utils/use-translation'

export default function Custom404() {
  const { t } = useTranslation()
  const router = useRouter()

  return (
    <div className={styles.page404}>
      <div className={styles.bg}></div>
      <h1>{t('wrong_door')}</h1>
      <ButtonBlue className={styles.btn} onClick={() => router.push('/')}>
        {t('go_back_home')}
      </ButtonBlue>
    </div>
  )
}
